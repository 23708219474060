<template>
	<v-container>
		<v-layout row wrap>
			<v-flex xs12 sm8 class="pa-1">
				<v-btn color="primary" @click="selectTrans(newTrans)">
					<v-icon>mdi-add</v-icon>
					{{ "crud.add" | trans }} {{ "translations" | trans }}
				</v-btn>
			</v-flex>
			<v-flex xs12 sm4 class="pa-1">
				<v-text-field
					solo
					single-line
					v-model="query"
					type="search"
					:placeholder="'crud.search' | trans"
					prepend-inner-icon="search"
				></v-text-field>
			</v-flex>
			<v-flex xs12 class="pa-1">
				<v-simple-table fixed-header>
					<template v-slot:default>
						<thead>
							<tr>
								<th style="min-width: 200px">{{ "group" | trans }}</th>
								<th style="min-width: 200px">{{ "key" | trans }}</th>
								<th style="min-width: 200px">English</th>
								<th style="min-width: 200px">Español</th>
								<th style="min-width: 100px">{{ "crud.actions" | trans }}</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(t, tindex) in filterBy(translations, query, 'group', 'key', 'text.en', 'text.es')"
								:key="t.id"
							>
								<td>
									<v-select dense outlined hide-details v-model="t.group" :items="fill_groups"> </v-select>
								</td>
								<td>
									<v-select
										v-model="t.key"
										dense
										outlined
										hide-details
										v-if="fill_texts[t.group]"
										:items="fill_texts[t.group]"
										item-text
									>
									</v-select>
								</td>
								<td>
									<v-text-field type="text" dense outlined hide-details v-model="t.text.en" />
								</td>
								<td>
									<v-text-field type="text" dense outlined hide-details v-model="t.text.es" />
								</td>
								<td>
									<v-btn
										small
										icon
										:disabled="!t.group || !t.key || !t.text.es"
										color="primary"
										@click="updateTranslation(t)"
									>
										<v-icon>save</v-icon>
									</v-btn>
									<v-btn small icon color="red" @click="removeTrans(t, tindex)">
										<v-icon>delete</v-icon>
									</v-btn>
								</td>
							</tr>
							<tr v-if="newTrans == trans">
								<td>
									<v-select v-model="trans.group" :items="fill_groups"> </v-select>
								</td>
								<td>
									<v-select
										v-model="trans.key"
										v-if="fill_texts[trans.group]"
										:items="fill_texts[trans.group]"
										:item-text="(val) => `${trans.group}.${val}`"
									>
									</v-select>
								</td>
								<td>
									<v-text-field type="text" v-model="trans.text.en" />
								</td>
								<td>
									<v-text-field type="text" v-model="trans.text.es" />
								</td>
								<td>
									<v-btn
										color="primary"
										@click="updateTranslation(trans)"
										icon
										small
										:disabled="!trans.group || !trans.key || !trans.text.es"
									>
										<v-icon>save</v-icon></v-btn
									>
									<v-btn small color="red" @click="trans = null" icon>
										<v-icon>delete</v-icon>
									</v-btn>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
				<v-alert type="info" tile dense border="bottom" :value="translations.length === 0">
					{{ "__.no translations" | trans }}
				</v-alert>
			</v-flex>
		</v-layout>
		<div class="modal fade" id="modal-helper" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"></div>
	</v-container>
</template>

<script>
export default {
	mounted() {
		this.getResource();
	},
	methods: {
		getResource() {
			this.api.get("translations").then((resp) => {
				this.translations = resp.data;
				this.fill_groups = Object.keys(this.api.trans);
				this.fill_texts = {};
				this.fill_groups.forEach((key) => {
					this.fill_texts[key] = Object.keys(this.api.trans[key]);
				});
				console.log(this.translations, this.api.trans);
			});
		},
		updateTranslation(trans) {
			var promise;
			var data = {
				group: trans.group,
				key: trans.key,
				text: { en: trans.text.en, es: trans.text.es },
			};
			if (trans.id) promise = this.api.put(`translations/${trans.id}`, data);
			else promise = this.api.post(`translations`, data);
			promise
				.then((resp) => {
					this.api.successful(resp);
					if (!trans.id) {
						this.translations.push(resp.data);
					}
					this.clearCache();
				})
				.catch((resp) => this.api.handleError(resp));
			this.trans = null;
			this.$set(this, "newTrans", {
				group: "literals",
				key: "",
				text: { en: "", es: "" },
			});
		},
		selectTrans(trans) {
			this.trans = trans;
		},
		removeTrans(trans, i) {
			var promise = this.api.delete(`translations/${trans.id}`);
			promise
				.then((resp) => {
					this.api.successful(resp);
					this.clearCache();
				})
				.catch((resp) => this.api.handleError(resp));
			this.translations.splice(i, 1);
		},
		clearCache() {
			this.api.post("translations/clear-cache", {}).catch(console.error);
		},
	},
	data() {
		return {
			query: "",
			i18n: {},
			translations: [],
			fill_texts: {},
			fill_groups: [],
			newTrans: {
				group: "literals",
				key: "",
				text: { en: "", es: "" },
			},
			trans: null,
		};
	},
	computed: {
		canSave() {
			return this.trans && this.trans.group.length > 0 && this.trans.group.text.es.length > 0;
		},
	},
};
</script>
