<template>
	<v-container>
		<v-card v-if="entry">
			<v-card-title>
				<v-icon left @click="entry = null">arrow_back</v-icon>
				<h4>{{ entry.header }}</h4>
				<v-spacer></v-spacer>
				<v-chip dark label small :color="colors[entry.level]">
					<v-icon left small>{{ icons[entry.level] }}</v-icon>
					{{ entry.level }}
				</v-chip>
			</v-card-title>
			<v-card-text>
				<pre style="overflow-x: scroll; line-height: 1.8rem; font-weight: bold" class="danger--text">
          {{ entry.stack }}
        </pre>
			</v-card-text>
			<v-divider></v-divider>
			<v-card-actions>
				<v-spacer></v-spacer>
				{{ entry.datetime }}
			</v-card-actions>
		</v-card>
		<v-list subheader two-line v-else name="logs">
			<v-subheader>
				<v-icon left @click="$router.back()">arrow_back</v-icon>
				<h3>{{ date }}</h3>
				<v-chip dark label small class="ml-2" v-if="level" :color="colors[level]">
					<v-icon left small>{{ icons[level] }}</v-icon>
					{{ level }}
				</v-chip>
				<v-spacer></v-spacer>
				<v-chip label color="primary">{{ logs.entries.length }}</v-chip>
			</v-subheader>
			<template v-for="(log, i) in logs.entries">
				<v-list-item :key="i" @click="selectEntry(log)">
					<v-list-item-content>
						<v-list-item-title>{{ log.header }}</v-list-item-title>
						<v-list-item-subtitle>{{ log.datetime }}</v-list-item-subtitle>
					</v-list-item-content>
					<v-list-item-action>
						<v-chip dark label :color="colors[log.level]">
							<v-icon left>{{ icons[log.level] }}</v-icon
							>{{ log.level }}</v-chip
						>
					</v-list-item-action>
				</v-list-item>
				<v-divider :key="'divider-' + i"></v-divider>
			</template>
		</v-list>
	</v-container>
</template>
<script>
export default {
	mounted() {
		this.getLogs(this.date, this.level);
	},
	data() {
		return {
			loading: false,
			entry: null,
			logs: [],
			colors: {
				all: "gray",
				debug: "cyan",
				info: "blue",
				error: "red",
				warning: "yellow darken-2",
				critical: "orange",
				emergency: "red darken-3",
				alert: "red lighten-3",
			},
			icons: {
				all: "list",
				debug: "mdi-lifebuoy",
				info: "mdi-information",
				error: "mdi-close-circle",
				warning: "mdi-alert",
				critical: "mdi-alert-decagram",
				emergency: "mdi-bug",
				alert: "mdi-bell-alert",
			},
		};
	},
	methods: {
		async getLogs(date, level = "all") {
			this.loading = true;
			this.logs = (await this.api.get(`logs/${date}`)).data;
			if (level != "all") {
				this.logs.entries = this.logs.entries.filter((log) => log.level == level);
			}
			this.loading = false;
		},
		selectEntry(entry) {
			this.entry = entry;
		},
	},
	props: {
		date: {
			type: String,
			required: true,
		},
		level: {
			type: String,
		},
	},
};
</script>
<style scoped></style>
