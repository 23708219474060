<template>
	<v-container>
		<v-card>
			<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
			<!-- Date List -->
			<v-list subheader two-line>
				<v-subheader class="text-capitalize">
					<v-icon left>list</v-icon>
					<h3>{{ "logs" | trans }}</h3>
					<v-spacer></v-spacer>
					<v-chip label :color="colors['all']" dark small>
						{{ totals.all }}
					</v-chip>
				</v-subheader>
				<v-divider></v-divider>
				<template v-for="(obj, date) in dates">
					<v-list-item :key="date" @click="gotoLogs(date)">
						<v-list-item-content>
							<v-list-item-title class="text-capitalize">{{ date | moment("dddd, LL") }}</v-list-item-title>
							<v-list-item-subtitle>
								<v-chip :color="colors['all']" dark small>
									<v-icon left>{{ icons["all"] }}</v-icon>
									{{ "Todos" | trans }}: {{ obj.all.count }}
								</v-chip>
								<v-chip :color="colors['error']" @click.stop="gotoLogs(date, 'error')" dark small>
									<v-icon left>{{ icons["error"] }}</v-icon>
									{{ "error" | trans }}: {{ obj.error.count }}
								</v-chip>
								<v-chip
									small
									dark
									:color="colors['debug']"
									class="hidden-sm-and-down mr-1"
									@click.stop="gotoLogs(date, 'debug')"
								>
									<v-icon left>{{ icons["debug"] }}</v-icon>
									{{ "debug" | trans }}: {{ obj.debug.count }}
								</v-chip>
								<v-chip
									small
									dark
									:color="colors['info']"
									@click.stop="gotoLogs(date, 'info')"
									class="hidden-sm-and-down mr-1"
								>
									<v-icon left>{{ icons["info"] }}</v-icon>
									{{ "info" | trans }}: {{ obj.info.count }}
								</v-chip>
								<v-chip
									small
									dark
									:color="colors['warning']"
									@click.stop="gotoLogs(date, 'warning')"
									class="hidden-sm-and-down mr-1"
								>
									<v-icon left>{{ icons["warning"] }}</v-icon>
									{{ "warning" | trans }}: {{ obj.warning.count }}
								</v-chip>
								<v-chip
									small
									dark
									:color="colors['critical']"
									@click.stop="gotoLogs(date, 'critical')"
									class="hidden-sm-and-down mr-1"
								>
									<v-icon left>{{ icons["critical"] }}</v-icon>
									{{ "critical" | trans }}: {{ obj.critical.count }}
								</v-chip>
								<v-chip
									small
									dark
									:color="colors['emergency']"
									@click.stop="gotoLogs(date, 'emergency')"
									class="hidden-sm-and-down mr-1"
								>
									<v-icon left>{{ icons["emergency"] }}</v-icon>
									{{ "emergency" | trans }}: {{ obj.emergency.count }}
								</v-chip>
								<v-chip
									small
									dark
									:color="colors['alert']"
									@click.stop="gotoLogs(date, 'alert')"
									class="hidden-sm-and-down mr-1"
								>
									<v-icon left>{{ icons["alert"] }}</v-icon>
									{{ "alert" | trans }}: {{ obj.alert.count }}
								</v-chip>
							</v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action>
							<div class="layout row">
								<v-btn title="Ver" small color="green" icon @click.stop="getLogs(date)"> <v-icon>search</v-icon></v-btn>

								<v-btn title="Descargar" small color="primary" icon @click.stop="download(date)">
									<v-icon>cloud_download</v-icon></v-btn
								>
							</div>
						</v-list-item-action>
						<v-list-item-action>
							<v-btn title="Eliminar" small color="red" icon @click.stop="deleteLog(date)">
								<v-icon>delete</v-icon></v-btn
							></v-list-item-action
						>
					</v-list-item>
					<v-divider :key="date + '-divider'"></v-divider>
				</template>
			</v-list>
		</v-card>
	</v-container>
</template>
<script>
import Swal from "sweetalert2";
export default {
	mounted() {
		this.getDates();
	},
	data() {
		return {
			loading: false,
			dates: {},
			logs: [],
			selectedDate: null,
			entry: null,
			colors: {
				all: "gray",
				debug: "cyan",
				info: "blue",
				error: "red",
				warning: "yellow darken-2",
				critical: "orange",
				emergency: "red darken-3",
				alert: "red lighten-3",
			},
			icons: {
				all: "list",
				debug: "mdi-lifebuoy",
				info: "mdi-information",
				error: "mdi-close-circle",
				warning: "mdi-alert",
				critical: "mdi-alert-decagram",
				emergency: "mdi-bug",
				alert: "mdi-bell-alert",
			},
		};
	},
	methods: {
		async getDates() {
			this.loading = true;
			this.dates = (await this.api.get("logs")).data;
			this.loading = false;
		},
		async download(date) {
			return this.api.downloadFile(`logs/${date}/download`, `${date}.log`);
		},
		async getLogs(date, level = "all") {
			this.loading = true;
			this.logs = (await this.api.get(`logs/${date}`)).data;
			if (level != "all") {
				this.logs.entries = this.logs.entries.filter((log) => log.level == level);
			}
			this.selectedDate = date;
			this.loading = false;
		},
		gotoLogs(date, level) {
			var uri = `/logs/${date}`;
			if (level) {
				uri += `/${level}`;
			}
			this.$router.push(uri);
		},
		async deleteLog(date) {
			var promise = await Swal.fire({
				title: `${this.$options.filters.trans("crud.delete_confirm")}`,
				type: "question",
				showConfirmButton: true,
				showCancelButton: true,
				focusCancel: true,
			});
			console.log(promise);
			if (!promise.value) {
				return;
			}
			this.loading = true;
			var resp = await this.api.delete(`logs/${date}`);
			this.loading = false;
			this.api.successful(resp);
			this.getDates();
		},
	},
	computed: {
		totals() {
			var totals = {
				all: 0,
				error: 0,
				debug: 0,
				info: 0,
				critical: 0,
				emergency: 0,
				warning: 0,
				alert: 0,
			};
			Object.keys(this.dates).forEach((date) => {
				totals["all"] += this.dates[date].all.count;
				totals["error"] += this.dates[date].error.count;
				totals["debug"] += this.dates[date].debug.count;
				totals["info"] += this.dates[date].info.count;
				totals["critical"] += this.dates[date].critical.count;
				totals["emergency"] += this.dates[date].emergency.count;
				totals["warning"] += this.dates[date].warning.count;
				totals["alert"] += this.dates[date].alert.count;
			});
			return totals;
		},
	},
};
</script>
<style scoped></style>
